// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
self.addEventListener('notificationclick', (event) => {
      console.log('[BetIt] Notification click Received.', event);
      event.notification.close();
      if (clients.openWindow && event.notification.data.url) {
        event.waitUntil(clients.openWindow(event.notification.data.url));
      }
});

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBUub-jPO7zSvh1rB0I6-nLbkOsqOAV-kQ",
  authDomain: "betit-100b1.firebaseapp.com",
  projectId: "betit-100b1",
  storageBucket: "betit-100b1.appspot.com",
  messagingSenderId: "865842901415",
  appId: "1:865842901415:web:ef4b1fb0c4e0c908068682",
  measurementId: "G-5RLXSYPTY4"
};



function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

        break;
      }
    }
  }

  return cookieValue;
}

const csrftoken = getCookie('csrftoken');
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
//
// You need to load the app to receive messages
// This allows Push Notifications to work when the site is up
function load_app(current_token) {
  current_token.then(() => {
    onMessage(messaging, (payload) => {
      showNotification(payload.notification);
    });
  });
}

if (getCookie('push_notifications') == 'True') {
  const current_token = getToken(messaging, {vapidKey: "BGbwNCbWTw-UNbyQOG71p8cZsP8kQGJdSzyyitbu9Z_OIHIhm9gRJQ1sH9W90vm0iR8Ch7IVR1-ifdTMXH2IFKo"});
  load_app(current_token);
}
//
//
function pushToken() {
  const current_token = getToken(messaging, {vapidKey: "BGbwNCbWTw-UNbyQOG71p8cZsP8kQGJdSzyyitbu9Z_OIHIhm9gRJQ1sH9W90vm0iR8Ch7IVR1-ifdTMXH2IFKo"});
  load_app(current_token);
  current_token.then((currentToken) => {
    fetch('/devices', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({
        'registration_id': currentToken,
        'type': 'web'
      }),
      credentials: "include",
    }).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    })
  }).catch((err) => {
    console.log(err);
  });
}

function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      pushToken();
    }
  });
}

function showNotification(notification) {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    var notification = new Notification(notification.title, notification);

  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        var notification = new Notification(notification.title, notification);
      }
    });
  }
}

if (document.getElementById('id_push_notifications')) {
  document.getElementById('id_push_notifications').addEventListener('click', () => {
    if (document.getElementById('id_push_notifications').checked) {
      requestPermission();
    }
  });
}

if (document.getElementById('push-notification-enabled')) {
  if (Notification.permission !== 'granted') {
      document.getElementById('push-notification-enabled').textContent = 'Push Notifications: ❌ (Browser Disabled)';
  }
  if ((Notification.permission === 'granted') && (navigator.serviceWorker.getRegistrations() === undefined)) {
    document.getElementById('push-notification-enabled').textContent = 'Push Notifications: ❌ (Service Worker Disabled)';
  }
}

